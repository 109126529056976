

// Parte resumen

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // Cambiar false para poner una imagen svg
};

const greeting = {
  username: "Elmer Sanchez - CV",
  title: "Bienvenido 🧑‍💻",
  subTitle: emoji(
    "Profesional Técnico en Ingeniería de Redes y Comunicaciones, actualmente convalidando estudios universitarios para Ing. de Sistemas, orientado a asumir retos, cumpliendo objetivos,  adaptándome a distintos cambios que se puedan presentar, comprometiéndome con el trabajo y a las nuevas tendencias tecnológicas.🚀"
  ),
  resumeLink:
    "https://drive.google.com/file/d/1_1APtmYG-pxdGmDIpv7ii3d1XwhM7gJz/view?usp=sharing",
  displayGreeting: true // Cambiar false para ocultar
};

// Link redes sociales

const socialMediaLinks = {
  //github: "",
  linkedin: "https://pe.linkedin.com/in/elmersr",
  gmail: "elmerurp@gmail.com",
  //gitlab: "",
 // facebook: ",
  //medium: "",
  display:true
};


// Skills Section

const skillsSection = {
  title: emoji("Skills 💪"),
  subTitle: "Esto son algunas tecnologias que he interactuado",
  skills: [
    emoji(
      "⚡ Conocimientos en entornos Windows, Linux, ambientes onpremise y cloud."
    ),
    emoji("⚡ Conocimientos de metodología ITIL, Scrum y de cultura DevOps"),
    emoji(
      "⚡ Habilidades en programación, base de datos y networking"
    )
  ],

  /* Repo web
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Windows",
      fontAwesomeClassname: "fab fa-windows"
    },
    {
      skillName: "Cloud Computing",
      fontAwesomeClassname: "fas fa-cloud"
    },
    {
      skillName: "Html5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },

    {
      skillName: "Jenkins",
      fontAwesomeClassname: "fab fa-jenkins"
    },
    {
      skillName: "WordPress",
      fontAwesomeClassname: "fab fa-wordpress"
    },
    {
      skillName: "SQL Server",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
   
    {
      skillName: "Vmware",
      fontAwesomeClassname: "fas fa-server"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "UNIV. TECNOLOGICA DEL PERU",
      logo: require("./assets/images/utp.png"),
      subHeader: "ING. DE SISTEMAS",
      duration: "Enero 2020 - Actualidad",
      desc: "Convalidando cursos para obtener el grado universitario"
 
    },
    {
      schoolName: "INSTITUTO SAN IGNACIO DE LOYOLA",
      logo: require("./assets/images/ISIL.jpg"),
      subHeader: "ING. DE REDES Y COMUNICACIONES",
      duration: "Agosto 2016 - Agosto 2019",
      desc: "Títulado a nombre de la nación"
    }
  ]
};

// 3 top habilidades

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Trabajo Colaborativo",
      progressPercentage: "75%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Toma de decisiones",
      progressPercentage: "85%"
    },
    {
      Stack: "Resolución de problemas",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  title: "Big Projects",
  subtitle:
    "Estos son algunos de los certificados logrados a lo largo de mi carrera !",
  experience: [
    {
      role: "Infraestructura TI",
      company: "Interseguro",
      companylogo: require("./assets/images/interseguro.png"),
      date: "Octubre 2020 – Actualidad",
  
      descBullets: [
        "Apoyo a gestión de pases a ambientes de testing y productivos de las aplicaciones",
        "Resolución de incidencias, requerimientos en aplicaciones, servidores web (IIS,Nginx), Windows, Linux, Docker",
        "Implementación de herramientas de automatización y mejora continua de los procesos operativos",
   
      ]
    },
    {
      role: "Practicante Profesional de TI",
      company: "Grupo TDM",
      companylogo: require("./assets/images/tdm.png"),
      date: "Febrero 2020 – Abril 2020",
     // desc:
     //   "Upgrade and Migrate projects to AWS, Ansible, Terraform, Cloudformation, resolve problems in Linux Enviroments, Virtualization,, CI/CD, Support and working in Infraestructure as a code, Docker, Kubernetes...",
      descBullets: [
          "Brindar servicio de soporte técnico a usuarios.",
          "Realizar la configuración y mantenimiento de equipos de cómputo, clonación, creación de usuarios, backup",
          "Levantamiento de información sobre el status de los equipos de cómputo de la compañía",
       
        ]  
    },
    {
      role: "Helpdesk",
      company: "NGR",
      companylogo: require("./assets/images/NGR.jpg"),
      date: "Agosto 2018 – Agosto 2019",
    //  desc:
      //  "Plan and implement new features in close collaboration with our product owners. Ensuring the stability, performance and other non-functional requirements of our services. Evaluate and introduce new technologies and frameworks. Share knowledge and ideas with the team. Focus on driving innovation from a technical and product perspectivere et dolore magna aliqua.",
      descBullets: [
          "Registro, clasificación y control de incidentes a través de la herramienta de mesa de ayuda (GLPI).",
          "Coordinar con proveedores para ejecución de cambios de equipos en las unidades de negocio.",
          "Apoyo en la elaboración de indicadores, reportes, KPIS del área"
      ]  
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Cursos Complementarios📚",
  subtitle: "Algunos cursos que he llevado en diferentes instituciones",
  projects: [
    {
      image: require("./assets/images/cibertec.png"),
      projectName: "Network Security Administrator",
      projectDesc: "Conocer el panorama de la seguridad informatica dentro de una empresa así como la aplicación de medidas correctivas y preventivas ante un evento de este tipo ",
      footerLink: [
        {
          name: "Ver certificado ",
          url: "https://drive.google.com/file/d/18VNlt4Kh15gfsRM8EdDRTAZ5juZiUHF0/view"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/tecsup.png"),
      projectName: "Configuracion de Equipos y Ethical Hacking",
      projectDesc:"Cursos practicos orientado a la parte tecnica del hardware de diferentes dispositivos y el otro a la explotación de vulnerabilidades en entornos de prueba ",
      footerLink: [
        {
          name: "Ver certificado 1",
          url: "https://drive.google.com/file/d/1GiZVh9Dp2qbP6bPYT-N_q7Rt4O7Htg1f/view"
        },
        {
          name: "Ver certificado 2",
          url: "https://academico.tecsup.edu.pe/pcc/#/home/certificado?c=341304&n=SD%2FCPE%2F19%2F1321"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Certificados Obtenidos🏆 "),
  subtitle:
    "Estos son algunos de los certificados logrados a lo largo de mi carrera !",

  achievementsCards: [
    {
      title: "Azure Fundamentals",
      subtitle:
        "Brinda los conocimientos y tecnologia acerca de la nube de Microsoft ",
      image: require("./assets/images/azurelogo.png"),
      footerLink: [
        {
          name: "Certificacion",
          url:
            "https://drive.google.com/file/d/1avA9efItE_0YP0X-8xkicuzZoCNI7sWj/view"
        },
        
        
      ]
    },
    {
      title: "Huawei Cloud Service",
      subtitle:
        "Conocimientos certificados acerca de los servicios de computación principales en la nube de Huawei",
      image: require("./assets/images/awsCloud.png"),
      footerLink: [
        {
          name: "Certificacion",
          url:
            "https://drive.google.com/file/d/1QI4nspm1Tenx48QcuH5AAz5ptTWWtaJZ/view"
        }
      ]
    },

    {
      title: "ITIL Foundation v3",
      subtitle: "Orientado a conocer las buenas practicas de servicio de TI",
      image: require("./assets/images/itil.png"),
      footerLink: [
        {
          name: "Certificacion",
          url: "https://drive.google.com/file/d/1ucPG89rvd6NpAwYXU9t6kgymSD0trf20/view"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};




const contactInfo = {
  title: emoji("Contáctame ☎️"),
  subtitle:
    "Deseas agendarme una entrevista laboral ",
  number: "+51 980614911",
  email_address: "elmerurp@gmail.com"
};





export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  contactInfo,
  twitterDetails
};
