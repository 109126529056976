// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/Agustina.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/Montserrat-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: \"Agustina Regular\";\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  src: local(\"Agustina Regular\"),\r\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");\r\n  font-display: swap;\r\n}\r\n@font-face {\r\n  font-family: \"Montserrat\";\r\n  src: local(\"Montserrat\"),\r\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\r\n  font-display: swap;\r\n}\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n    monospace;\r\n}\r\nhtml,\r\nbody {\r\n  scroll-behavior: smooth;\r\n}\r\nbody {\r\n  margin: 0;\r\n  font: 19px / 23px Montserrat, \"Montserrat\", sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n/* Media Query */\r\n@media (max-width: 1380px) {\r\n  html,\r\n  body {\r\n    font-size: 16px;\r\n    line-height: normal;\r\n  }\r\n}\r\n", ""]);
// Exports
module.exports = exports;
